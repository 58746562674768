import React from 'react'
import { Link } from 'gatsby'

export default function about() {
    return (
        <div className="content-wrapper about-page">
            <h1>Cloud West</h1>
            <div className="standfirst">
                <p>
                    Cloud West is a Perth-based freelance web and software development business.
                </p>
                <p>
                    It was set up in 2020 by Donald Wade, to help small to medium sized businesses and startups get the most out of cloud technology.
                </p>
                <p>
                    Donald has nearly two decades of experience across sales, marketing, technology and consulting, all of which he can use to help your business be the best it can be.
                </p>
            </div>
            <div className="body">
                <p>We can help you with any aspect of your technology journey, from building or upgrading your website, through to custom software design, development and consulting.</p>
                <p>Cloud West is experienced at the forefront of today's cloud based technology, and we can explain it in terms that make sense to you.</p>
                <p>We'll help you understand what your business needs, then we can build it, host it and run it for you in the cloud.</p>
            </div>
            <div>
                <h1>

                    <Link to="#process" className="down">
                        &gt; </Link>

                </h1>
            </div>
        </div>
    )
}
