import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import About from '../components/newStructure/about'
import Contact from '../components/newStructure/contact'
import Process from '../components/newStructure/process'
import BlackExplosionBg from '../components/image-components/blackExplosionBg'

export default function dooby() {
    return (
        <div className="homepage-wrapper">
            <div id="top" className="section section-1">
                <div className="image-wrapper not-image-wrapper-top">
                    <StaticImage
                        src="../images/licensed/AdobeStock_85653011.jpeg"
                        alt="color splash"
                        className="image"
                    />
                </div>
                <div className="site-heading">
                    <div className="heading-wrapper">
                        <h1>
                            <span className="heading heading-first">Cloud West</span>
                            <span className="heading heading-second"> Blazingly fast websites*. Beautifully designed. </span>
                            <div>
                                <Link to="#about" className="btn-homepage">
                                    &gt; </Link>
                            </div>
                        </h1>
                        <div className="asterisk-box">
                            * and some other really useful things too.
                        </div>
                    </div>
                </div>
            </div>
            <div className="section section-2">
                <div id="about" className="section-text">
                    <About />
                </div>
                <div className="image-wrapper">
                    <StaticImage
                        src="../images/licensed/AdobeStock_259217772.jpeg"
                        alt="color splash"
                        className="image"
                    />
                </div>
            </div>
            <div className="section section-3">
                {/* <div className="image-wrapper">
                    <StaticImage
                        src="../images/AdobeStock_228976900_Preview.jpeg"
                        alt="color splash"
                        className="background-image"
                    />
                </div> */}
                <div id="process" className="section-text">
                    <Process />
                </div>
            </div>
            <div className="section section-4">
                <div className="image-wrapper">
                    <StaticImage
                        src="../images/licensed/AdobeStock_259231683.jpeg"
                        alt="color splash"
                        className="image"
                    />
                </div>
                <div id="contact" className="section-text contact">
                    <Contact />
                </div>
            </div>
        </div>
    )
}
