import React from 'react'
import { Link } from 'gatsby'
// import { Formik, Form, Field, ErrorMessage } from 'formik';

export default function contact() {
  return (

    //  <div className="content-wrapper contact-page">
    <div className="contact-page">
      <h1>Contact us</h1>
      <h2>To find out how we can help you, please call now on <span className="new-line"><a href="tel:+61415444309">0415 444 309</a></span></h2>
      <p>or email <a href="mailto:info@cloudwest.io">info@cloudwest.io</a></p>
      <p>We aim to get back to all enquiries within 24 hours.</p>

      {/* <Formik
       initialValues={{ email: '', phone: '', message: '' }}
       validate={values => {
         const errors = {};
         if (!values.email) {
           errors.email = 'Required';
         } else if (
           !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
         ) {
           errors.email = 'Invalid email address';
         }
         return errors;
       }}
       onSubmit={(values, { setSubmitting }) => {
         setTimeout(() => {
           alert(JSON.stringify(values, null, 2));
           setSubmitting(false);
         }, 400);
       }}
     >
       {({ isSubmitting }) => (
         <Form>
           <Field type="email" name="email" className="full-width-field" />
           <ErrorMessage name="email" component="div" />
           <Field type="tel" name="phone" className="full-width-field" />
           <ErrorMessage name="phone" component="div"/>
           <Field
                as="textarea"
                name="message"
                className="contact-form-textarea"
                rows={10}
                cols={50}
           />
           <ErrorMessage name="message" component="div" />
           {/* <button type="submit" disabled={isSubmitting}>
             Submit
           </button> */}
      {/* <input type="submit" disabled={isSubmitting} className="submit-button" value="Send" /> */}
      {/* </Form>
       )}
     </Formik> */}
      <div>

        <h1>
          <Link to="#top" className="up">
            &gt; </Link>
        </h1>


      </div>
    </div>
  )
}