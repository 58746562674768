import React from 'react'
import { Link } from 'gatsby'

export default function process() {
    return (

        <div className="content-wrapper process-page process-grid">
            <div id="discover">
                <h1><span className="small-heading">1.</span> Discover</h1>
                <p>
                    &gt;
                What do you need?</p>
            </div>
            <div id="architect">
                <h1><span className="small-heading">2.</span> Architect</h1>
                <p>
                    &gt;
                    How does that translate into technology?
            </p>

            </div>
            <div id="design">
                <h1><span className="small-heading">3.</span> Design</h1>
                <p>
                    &gt;
                What should it look like?</p>
            </div>
            <div id="develop">
                <h1><span className="small-heading">4.</span> Develop</h1>
                <p>
                    &gt;
                Turning the idea and design into working code</p>
            </div>
            <div id="launch">
                <h1><span className="small-heading">5.</span> Launch</h1>
                <p>
                    &gt;
                Putting your product out into the world</p>
            </div>
            <div id="host">
                <h1><span className="small-heading">6.</span> Host</h1>
                <p>
                    &gt;
                Providing a home for your website</p>

            </div>
            <div id="maintain">
                <h1><span className="small-heading">7.</span> Maintain</h1>
                <p>
                    &gt;
                Keep things running smoothly</p>
            </div>
            <div id="enhance">
                <h1><span className="small-heading">8.</span> Enhance</h1>
                <p>
                    &gt;
                Incrementally improve your site </p>
            </div>
            <div>
                <h1>
                                <Link to="#contact" className="down">
                                    &gt;</Link>
                </h1>
                            </div>

        </div>
    )
}
